<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs">Ajouter la facture du CCAS</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Ajouter la facture du CCAS</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <span v-if="loading_clients" class="body-2">Chargement des tiers...</span>
        <v-autocomplete v-model="clientId" :items="clients" item-text="name" item-value="id" :disabled="createDirectly" label="Clients (Logiciel)"></v-autocomplete>
        <v-row no-gutters>
          <v-col cols="12">
            <span>
              <strong>Apport personnel</strong><br>
              Total : {{ total_apport.toFixed(2) }} € <br>
              Encaissé : {{ encaisse_apport.toFixed(2)}} € <br>
              Reste à encaisser : {{reste_apport.toFixed(2)}} € <br>
            </span>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="montantCCAS" type="number" step="0.01" label="Montant"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="creating" @click="generateFacture">Générer</v-btn>
        <v-progress-circular v-if="creating" indeterminate color="primary"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {ReglementRepository} from "@/repository/reglement";

export default {
  name: 'FactureCCASForm',
  props: {
    dossier: Object,
  },
  async mounted() {
    this.loading_clients = true;
    await this.$store.dispatch("clients/fetchClients");
    this.loading_clients = false;
  },
  methods: {
    find_saisie(source) {
      return this.dossier.saisies?.find(saisie => saisie.source === source);
    },
    async generateFacture() {
      this.creating = true;
      let payload = {
        client_id: this.clientId,
        montant_ccas: this.montantCCAS,
        dossier: this.dossier.id,
      }
      var repo = new ReglementRepository();
      let result = await repo.createFactureCCAS(payload);
      if(result === "SUCCESS") {
        this.$store.dispatch("annonce/annonceSuccess", "La facture du CCAS a bien été créé..");
        this.$emit("reload");
      }
      this.creating = false;
      this.dialog = false;

    }
  },
  computed: {
    saisie_apport() {
      return this.find_saisie("BENEFICIAIRE - APPORT");
    },
    apport_personnel() {
      return this.dossier.financements.find((e) => e.origine === "Apport personnel");
    },
    encaisse_apport() {
      return this.saisie_apport ? this.saisie_apport.total : 0;
    },
    total_apport() {
      return this.apport_personnel ? parseFloat(this.apport_personnel.montant) : 0;
    },
    reste_apport() {
      return this.total_apport - this.encaisse_apport;
    },
    clients() {
      return this.$store.getters["clients/clients"];
    }
  },
  data() {
    return {
      createDirectly: false,
      creating: false,
      dialog: false,
      clientId: false,
      loading_clients: false,
      montantCCAS: null,
    };
  },
}
</script>