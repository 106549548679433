<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{on, attrs}">
      <v-btn text v-on="on" v-bind="attrs" color="primary">
        Importer manuellement
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Importer le document manuellement</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-file-input label="Fichier" v-model="documentFile"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="uploading" class="white--text" @click="updateDocument">Modifier</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ReplaceDocument',
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documentFile: null,
      dialog: false,
      uploading: false,
    }
  },
  methods: {
    async updateDocument() {
      this.uploading = true;
      let result = await this.$store.dispatch("documents/patchDocumentWithFile", {id: this.document.id, signed_file: this.documentFile});
      if(result) {
        this.$store.dispatch("annonce/annonceSuccess", "Document mis à jour");
        this.$emit("reload");
      }
      this.uploading = false;
      this.dialog = false;
    }
  }
}
</script>
<style scoped>
canvas {
  border: darkgrey 1px solid;
}
</style>