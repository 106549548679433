import { render, staticRenderFns } from "./BaseDocumentaire.vue?vue&type=template&id=56fef913&scoped=true"
import script from "./BaseDocumentaire.vue?vue&type=script&lang=js"
export * from "./BaseDocumentaire.vue?vue&type=script&lang=js"
import style0 from "./BaseDocumentaire.vue?vue&type=style&index=0&id=56fef913&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56fef913",
  null
  
)

export default component.exports