<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" color="red" text class="white--text">
        <v-icon>mdi-trash-can</v-icon>
        Supprimer la saisie
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="red">
        <v-toolbar-title class="white--text">Supprimer la saisie</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        Supprimer cette saisie ? Cela supprimera tous les règlements associés !
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" :disabled="deleting" class="white--text" @click="deleteSaisie">Confirmer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {SaisieRepository} from "@/repository/saisie";

export default {
  name: 'SaisieDeleteDialog',
  props: {
    saisie: Object,
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    }
  },
  methods: {
    async deleteSaisie() {
      this.deleting = true;
      var repo = new SaisieRepository();
      await repo.delete(this.saisie);
      this.$store.dispatch("Saisie supprimée");
      this.$emit("reload");
      this.deleting = false;
      this.dialog = false;
    },
  }
}
</script>