<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn text v-on="on" v-bind="attrs" :color="color">
        <v-icon>{{ operation ==='create' ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
        {{ text }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar :color="color">
        <v-toolbar-title class="white--text">{{ text }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select label="Source" v-model="saisieSource" :items="sources"></v-select>
      </v-card-text>
      <v-card-actions>
        <v-progress-circular indeterminate :color="color" v-if="loading"></v-progress-circular>
        <v-btn v-if="operation === 'create'" @click="createSaisie" :disabled="loading" :color="color">
          Ajouter
        </v-btn>
        <v-btn v-else @click="updateSaisie" :disabled="loading" :color="color">
          Mettre à jour
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {TYPES_DECAISSEMENT, TYPES_DECAISSEMENT_HORS_PLAN, TYPES_ENCAISSEMENT} from "@/constants/Financement";
import {SaisieRepository} from "@/repository/saisie";


export default {
  name: 'SaisieForm',
  props: {
      dossier_id: Number,
      dossier: Object,
      type: String,
      operation: {
        type: String,
        default: "create",
      },
    saisie: Object,
  },
  mounted() {
    if(this.operation === "update") {
      this.saisieSource = this.saisie.source;
    }
  },
  data() {
    return {
      saisieSource: null,
      dialog: false,
      loading: false,
    }
  },
  computed: {
    sources() {
      let sources = this.type === 'Encaissement' ? TYPES_ENCAISSEMENT : TYPES_DECAISSEMENT.concat(TYPES_DECAISSEMENT_HORS_PLAN);
      return sources.filter(source => this.usedSaisies.indexOf(source) === -1);
    },
    isEncaissement() {
      return this.type === 'Encaissement';
    },
    color() {
      return this.isEncaissement ? "primary": "secondary";
    },
    usedSaisies() {
      return this.dossier.saisies.map(saisie => saisie.source);
    },
    textColor() {
      return this.isEncaissement ? "primary--text" : "secondary--text";
    },
    text() {
      var action = this.operation === "create" ? "Créer" : "Mettre à jour";
      var obj = this.isEncaissement ? " un encaissement" : " un décaissement";
      return action + obj;
    }
  },
  methods: {
    async createSaisie() {
      this.loading = true;
      try {
        let repository = new SaisieRepository();
        await repository.post({
          dossier: this.dossier_id,
          type: this.type,
          source: this.saisieSource
        });
        this.$store.dispatch("annonce/annonceSuccess", "Saisie créé");
        this.$emit("reload");

      } finally {
        this.dialog = false;
        this.loading = false;
      }
    },
    async updateSaisie() {
      this.loading = true;
      try {
        let repository = new SaisieRepository();
        await repository.update({
          id: this.saisie.id,
          dossier: this.dossier_id,
          type: this.type,
          source: this.saisieSource
        });
        this.$store.dispatch("annonce/annonceSuccess", "Saisie créé");
        this.$emit("reload");

      } finally {
        this.dialog = false;
        this.loading = false;
      }
    }
  }
}
</script>