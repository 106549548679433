<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-icon v-on="on" v-bind="attrs" color="primary">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier l'emploi</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field v-model="honoraireOrigine" label="Origine de l'emploi"></v-text-field>
        <v-text-field v-model="honoraireMontant" type="number" label="Montant"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="editing" @click="editHonoraire">Modifier</v-btn>
        <v-progress-circular v-if="editing" indeterminate color="primary"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EditEmploiDialog',
  props: {
    honoraire: Object,
  },
  data() {
    return {
      honoraireOrigine: this.honoraire.origine ?? "",
      honoraireMontant: this.honoraire.montant ?? null,
      editing: false,
      dialog: false,
    };
  },
  methods: {
    async editHonoraire() {
      this.editing = true;
      let devis = await this.$store.dispatch("honoraire/patchHonoraire", {id: this.honoraire.id, origine: this.honoraireOrigine, montant: this.honoraireMontant})
      this.$emit("reload");
      if (devis !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Emploi mis à jour");
      }
      else {
        this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue, vérifiez si le devis à " +
            "bien été mis à jour.")
      }
      this.editing = false;
      this.dialog = false;
    }
  }
}
</script>