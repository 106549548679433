<template>
  <v-card @click="action !== null ? action : null" :color="color" class="ma-4 pa-3 text-center">
    <h2 class="white--text">{{title}}<br> <span v-if="value">{{ value }}</span> <span v-else><slot></slot></span></h2>
  </v-card>
</template>

<script>
export default {
  name: "StatCard",
  props: {
    color: String,
    action: Function,
    title: String,
    value: String,
  }
}
</script>

<style scoped>

</style>