<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" color="primary">Ajouter un encaissement</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Ajouter le règlement</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="addReglementForm">
          <v-select v-model="saisieSource" :items="sources" label="Source"></v-select>
          <v-select v-model="reglementProvenance" :items="provenances" label="Provenance"></v-select>
          <v-text-field label="Montant" v-model="reglementMontant" type="number"></v-text-field>
          <date-field :rules="[notNullNorEmpty]" label="Date de demande" v-model="reglementDate"></date-field>
          <v-select label="Statut" :disabled="logged_user.group !== 'Administrateur'" :items="statuses" item-text="name"
                    item-value="value" v-model="reglementStatus"></v-select>
          <v-file-input v-model="reglementFile" label="Justificatif"></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="createDemandeReglement" :disabled="creating" color="primary">
          Créer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateField from "@/components/base/DateField.vue";
import {AuthMixin} from "@/mixins/AuthMixin";
import {PROVENANCES} from "@/constants/Financement";
import {ReglementRepository} from "@/repository/reglement";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";

export default {
  name: 'AddDemandeReglement',
  components: {DateField},
  props: {
    dossier: {},
    sources: [],
    montant: Number,
  },
  mixins: [AuthMixin, ValidationRulesMixin],
  data() {
    return {
      dialog: false,
      creating: false,
      saisieSource: this.sources.length > 0 ? this.sources[0] : null,
      reglementMontant: this.montant,
      reglementStatus: "waiting_payment",
      reglementDate: null,
      reglementFile: null,
      reglementProvenance: null,
    }
  },
  computed: {
    statuses() {
      return [
        {value: "waiting_payment", name: "En attente de paiement"},
        {value: "paid", name: "Payé"}
      ]
    },
    provenances() {
      return PROVENANCES;
    }
  },
  methods: {
    async createDemandeReglement() {
      if(!this.$refs.addReglementForm.validate()) {
        this.$store.dispatch("annonce/annonceWarning", "Il reste des erreurs dans votre formulaire.");
        return;
      }
      this.creating = true;
      try {
        let repository = new ReglementRepository();
        let result = await repository.createDemandeReglement({
          source: this.saisieSource,
          provenance: this.reglementProvenance,
          date: this.reglementDate,
          montant: this.reglementMontant,
          status: this.reglementStatus,
          dossier: this.dossier.id,
          file: this.reglementFile,
        });
        if (result) {
          this.$emit("reload");
          await this.$store.dispatch("annonce/annonceSuccess", "Commande payé.");
        }
      } finally {
        this.creating = false;
        this.dialog = false;
      }
    },
  }
}
</script>