<template>
  <div>
    <v-toolbar>
      <v-toolbar-title class="primary--text">
        {{type}}s du dossier de {{ dossier.beneficiaire_first_name }}
        {{ dossier.beneficiaire_last_name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn :to="'/dossier/' + dossier.id" color="primary" text>
          <v-icon>mdi-arrow-left</v-icon>
          Retour au dossier
        </v-btn>
        <SaisieForm v-if="type === 'Encaissement'" @reload="$emit('reload')" :dossier_id="dossier.id" :dossier="dossier" operation="create" type="Encaissement"/>
        <SaisieForm v-if="type === 'Décaissement'" @reload="$emit('reload')" :dossier_id="dossier.id" :dossier="dossier" operation="create" type="Décaissement"/>
      </v-toolbar-items>
    </v-toolbar>


    <div class="my-4" v-for="saisie in saisies_type" :key="saisie.id">
      <v-row class="pa-4" no-gutters justify="space-between">
        <h2 class="primary--text">{{ saisie.source }}</h2>
        <saisie-delete-dialog v-if="isAdmin" :saisie="saisie" @reload="$emit('reload')"></saisie-delete-dialog>
        <saisie-form v-if="isAdmin" :saisie="saisie" :type="saisie.type" :dossier="dossier" :dossier_id="dossier.id" operation="update" @reload="$emit('reload')"></saisie-form>
        <ReglementForm :icon="false" @reload="$emit('reload')" :saisie="saisie" operation="create"/>
      </v-row>

      <v-simple-table dense>
        <template>
          <thead>
          <tr>
            <th v-if="saisie.type === 'Encaissement'">Date de demande</th>
            <th>Date de règlement</th>
            <th v-if="saisie.type === 'Encaissement'">Provenance</th>
            <th v-if="saisie.type === 'Décaissement'">Entreprise</th>
            <th>Montant</th>
            <th v-if="saisie.type === 'Décaissement'">Date de la facture</th>
            <th v-if="saisie.type === 'Décaissement'">Référence de la facture</th>

            <th>Statut</th>
            <th>Justificatif</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="reglement in saisie.reglements" :key="reglement.id">
            <td v-if="saisie.type === 'Encaissement'">{{ getFormattedDate(reglement.date_demande) }}</td>
            <td>{{ getFormattedDate(reglement.date_reglement) }}</td>
            <td v-if="saisie.type === 'Encaissement'">{{reglement.provenance}}</td>
            <td v-if="saisie.type === 'Décaissement'">{{reglement.entreprise_name}}</td>
            <td>{{ reglement.montant }} €</td>
            <td v-if="saisie.type === 'Décaissement'">{{getFormattedDate(reglement.date_facture)}}</td>
            <td v-if="saisie.type === 'Décaissement'">{{reglement.reference_facture}}</td>
            <td>
              <reglement-status-chip  :reglement="reglement"></reglement-status-chip>
            </td>
            <td>
              <v-btn target="_blank" :href="getUrl(reglement.file)" icon color="primary" v-if="reglement.file">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </td>
            <td>
              <switch-reglement-status-menu v-if="(reglement.status === 'waiting' && isAdmin) || (reglement.status === 'check' && hasFinanceRole)" @reload="$emit('reload')" :reglement="reglement"></switch-reglement-status-menu>
              <v-btn color="red" v-if="reglement.status !== 'paid' || hasFinanceRole" icon @click="deleteReglement(reglement)"><v-icon>mdi-trash-can</v-icon></v-btn>
              <ReglementForm :icon="true" v-if="(reglement.status !== 'check' && reglement.status !== 'paid' || hasFinanceRole)" :reglement="reglement" @reload="$emit('reload')" :saisie="saisie" operation="update"/>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="font-weight-bold">TOTAL</td>
            <td class="font-weight-bold">{{saisie.total.toFixed(2)}} €</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import SwitchReglementStatusMenu from "@/views/SwitchReglementStatusMenu.vue";
import ReglementForm from "@/views/ReglementForm.vue";
import * as config from "@/config.json";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import {ReglementRepository} from "@/repository/reglement";
import SaisieForm from "@/views/SaisieForm.vue";
import {AuthMixin} from "@/mixins/AuthMixin";
import SaisieDeleteDialog from "@/views/SaisieDeleteDialog.vue";

export default {
  name: 'Encaissements',
  components: {
    SaisieDeleteDialog,SaisieForm, ReglementForm, SwitchReglementStatusMenu, ReglementStatusChip},
  mixins: [DateUtilMixin, AuthMixin],
  props: {
    dossier: Object,
    type: {
      type: String,
      default: "Encaissement"
    }
  },
  computed: {
    saisies_type() {
      return this.dossier.saisies.filter(saisie => saisie.type === this.type);
    }
  },
  methods: {
    getUrl(path) {
      return config.BASE_URL + path;
    },
    async deleteReglement(reglement) {
      this.deleting = true;
      try {
        let repository = new ReglementRepository();
        let result = await repository.delete(reglement);
        if (result === "success") {
          this.$store.dispatch("annonce/annonceSuccess", "Règlement supprimé !");
          this.$emit("reload");
        }
      } finally {
        this.deleting = false;
      }
    },
  }
}
</script>